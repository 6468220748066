import React, { useState } from 'react';
import './ExpiryFeedback.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ExpiryFeedback = ({ userId }) => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const location = process.env.REACT_APP_LOCATION;

  const handleSubmit = async () => {
    const feedbackData = {
      questions: [
        {
          question: "How much did you enjoy this digital guide?",
          answer: rating,
          type: "rating"
        },
        {
          question: "Please share any other feedback you have for us:",
          answer: feedback,
          type: "text"
        }
      ],
      metadata: {
        userId: userId,
        location: location,
        timestamp: new Date().toISOString()
      }
    };

    setSubmitted(true);

    fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedbackData)
    }).catch(error => {
      console.error('Error submitting feedback:', error);
    });
  };

  return (
    <div className="expiry-overlay">
      <div className="expiry-content">
        <h1 className="expiry-title">
          Thank you for participating in our trial!
        </h1>
        
        {!submitted && (
          <p className="expiry-intro">
            If you have a moment, your feedback would be incredibly valuable.
          </p>
        )}
        
        {!submitted ? (
          <>
            <div className="survey-question" style={{ textAlign: 'left' }}>
              <p style={{ fontWeight: 'bold' }}>How much did you enjoy this digital guide?</p>
              <div className="rating-container">
                <div className="rating-labels">
                  <span className="rating-label">Not at all</span>
                  <span className="rating-label">Loved it!</span>
                </div>
                <div className="rating-buttons">
                  {[0, 1, 2, 3, 4, 5].map((value) => (
                    <button
                      key={value}
                      className={`rating-button ${rating === value ? 'selected' : ''}`}
                      onClick={() => setRating(value)}
                    >
                      {value}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="survey-question" style={{ textAlign: 'left' }}>
              <p style={{ fontWeight: 'bold' }}>Please share any other feedback you have for us:</p>
              <textarea
                className="feedback-textarea"
                placeholder="Tell us what you enjoyed or what could be improved..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </div>

            <button 
              className="popup-button primary submit-button" 
              onClick={handleSubmit}
              disabled={rating === null && feedback.trim() === ''}
            >
              Submit Feedback
            </button>
          </>
        ) : (
          <>
            <p style={{ textAlign: 'center' }} className="thank-you-message">
              Thank you for your feedback! Please close this tab.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpiryFeedback;