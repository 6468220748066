import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PopupStyles.css';

const AIInfoPopup = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    // Add console.log to debug
    console.log('Overlay clicked', e.target, e.currentTarget);
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="aiinfo-title">About Our AI-Powered Virtual Guide</h2>
        <div className="popup-text">
          <p>Our virtual guide uses artificial intelligence to help you explore Madame Tussauds in a whole new way.</p>
          <p><strong>Key Features:</strong></p>
          <ul style={{ paddingLeft: '17px' }}> {/* Adjusted padding to reduce indentation */}
            <li><strong>Camera-based recognition:</strong> Simply point your camera, and the guide will recognise which figure you're looking at. Your camera feed is never stored.</li>
            <li><strong>Answer Your Questions:</strong> Want to know more? Just ask, and the guide will provide some interesting stories. Check out the green tags to view which information has been verified by our team.</li>
          </ul>
          <p><strong>Keep in Mind:</strong> This is an experimental app and you may experience some bugs or inaccuracies. Treat this as an exploratory experience and double-check any critical facts.</p>
          <p style={{ color: '#008626' }}><strong>Finally:</strong> Don't forget to provide us your feedback, good or bad! Click the survey button in the top right corner.</p>
        </div>
      </div>
    </div>
  );
};

export default AIInfoPopup;