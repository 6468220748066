import React from 'react';
import './PopupStyles.css';

const ConsentPopup = () => {
  return (
    <div className="consent-overlay">
      <div className="consent-content">
        <div className="consent-content-inner">
          <h2>Welcome to our Digital Guide!</h2>
          <div className="consent-text">
            <p>By using this app you agree to the following:</p>
            <ul>
              <li><strong>Experimental AI Features:</strong> You may encounter occasional bugs.</li>
              <li><strong>Usage Data for Improvement:</strong> We collect general usage data to improve the app. No personal information is collected.</li>
              <li><strong>AI-Generated Responses:</strong> Responses are generated by AI and may not always be fully accurate. Please verify important details.</li>
            </ul>
            <p>
              For more details, please see our{' '}
              <a href="https://tell-me.ai/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              {' '}and{' '}
              <a href="https://tell-me.ai/terms" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentPopup;