import React, { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import usePageHeight from '../Components/usePageHeight';
import VideoCapture from '../Components/VideoCapture';
// import ObjectSummary from '../Components/ObjectSummary';
import ObjectConversation from '../Components/ObjectConversation';
// import {useInView} from '../Components/useInView';
import './Object.css';
import NavFromObject from '../Components/NavFromObject';
// import Lottie from 'react-lottie';
// import ScanAnimation from '../assets/ScanAnimation.json';
// import { Oval  } from 'react-loader-spinner'; // Import the spinners you want to use


const ObjectPage = ({ userLanguage, userType, openInput, userId, onConsent, hasConsented }) => {
  const [responseTitle, setResponseTitle] = useState('');
  const [responsePrompt, setResponsePrompt] = useState('');
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [objectId, setObjectId] = useState('');
  const [objectSubtitle, setObjectSubtitle] = useState('');
  const [responseSummary, setResponseSummary] = useState('');
  const [thumbnailImage, setThumbnailImage] = useState('');
  // const [showConversation, setShowConversation] = useState(false);
  const conversationRef = useRef(null);  //NOT NEEDED NOW, REMOVE LATER
  const videoCaptureRef = useRef(null);
  const videoContainerRef = useRef(null);
  const { height } = usePageHeight();
  // const isVideoContainerVisible = useInView(videoContainerRef, "0px");
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isFirstPage, setisFirstPage] = useState(true);
  const showObjectDetails = responseTitle && responseTitle !== "No object detected"; 
  // const [globalAudio, setGlobalAudio] = useState(null); 
  const globalAudio = useRef(new Audio());
  const [isRecording, setIsRecording] = useState(false);
  // const recordingBoxRef = useRef(null);
  const [isInferenceRunning, setIsInferenceRunning] = useState(true);
  const [hasInferenceStoppedLong, setHasInferenceStoppedLong] = useState(false);
  const [isFirstInferenceComplete, setIsFirstInferenceComplete] = useState(false);
  const [showWifiMessage, setShowWifiMessage] = useState(false);

  // New state variable for the overlay visibility
  // const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  

  // const defaultOptionsScan = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: ScanAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  useLayoutEffect(() => {  //USEEFFECT ALSO WORKS. THIS IS TO REPLACE THE below useeffect as it actually scrolled up to the top component. useLayoutEffect fires synchronously after all DOM mutations, ensuring that the DOM is updated before you try to scroll.
    const recordingBox = conversationRef.current;
    if (recordingBox) {
      recordingBox.scrollTop = recordingBox.scrollHeight;
    }
  }, [isRecording]);

  const unlockAudio = () => {
    const silentAudio = '/silent.mp3';
    globalAudio.current.src = silentAudio;
    globalAudio.current.play().catch((error) => {
      console.error('Playback failed:', error);
    });
    // Hide the overlay after the first interaction
    //setIsOverlayVisible(false);
  };

  // const handleOverlayClick = () => {
  //   unlockAudio();
  //   setIsOverlayVisible(false);
  // };
  
  // useEffect(() => {
  //   // const unlockAudio = () => {
  //   //   // Path to your silent.mp3 file in the public directory
  //   //   const silentAudio = '/silent.mp3'; // Adjust if your public URL path is different - IS IT ACTUALLY LOOKING AT IT?
  //   //   const audio = new Audio(silentAudio);
  //   //   audio.play().then(() => {
  //   //     setGlobalAudio(audio);
  //   //   }).catch((error) => {
  //   //     // Handle any errors that occur during playback
  //   //     console.error('Playback failed:', error);
  //   //   });
  //   // };
  
  //   // Add event listeners for user interactions
  //   window.addEventListener('click', unlockAudio, { once: true });
  //   window.addEventListener('touchstart', unlockAudio, { once: true });
  //   window.addEventListener('scroll', unlockAudio, { once: true });
  
  //   // Cleanup function to remove the event listeners
  //   return () => {
  //     window.removeEventListener('click', unlockAudio);
  //     window.removeEventListener('touchstart', unlockAudio);
  //     window.removeEventListener('scroll', unlockAudio);
  //   };
  // }, []);

  
  const handleResponseTitleChange = useCallback((newTitle, newPrompt, newSummary, newThumbnail, newAdditionalInstructions, newSubtitle, newObjectId) => { //THESE NAMES DONT USE THE SAME AS WHAT IS IN THE VIDEO COMPONENT, BUT IT IS REFERRING TO WHAT COMES BACK FROM THE BACKEND.
    if (newTitle && newTitle !== "No object detected") {
      setResponseTitle(newTitle);
      setResponsePrompt(newPrompt);
      setResponseSummary(newSummary);
      setThumbnailImage(newThumbnail);
      setAdditionalInstructions(newAdditionalInstructions);
      setObjectSubtitle(newSubtitle);
      setObjectId(newObjectId);  // Add this line to set the objectId
    }
  }, []);

  console.log(userLanguage);
  console.log(openInput);


  const handleFindOutMoreClick = () => {
    setisFirstPage(false);
    // setShowConversation(true);
    if (videoCaptureRef.current) {
        setTimeout(() => {
            videoCaptureRef.current.stopCamera(); // This now calls temporary stop
        }, 400);
    }
  };

  const handleReturnToCameraClick = () => {
    setisFirstPage(true); // Show "Tell-me more!" button
    // setShowConversation(false);
    if (videoCaptureRef.current) {
      videoCaptureRef.current.startCamera();
    }
    
  };


  const handleScroll = (event) => {
    if (!showObjectDetails) {
      event.preventDefault();
      return;
    }

    // const scrollThreshold = 15; // Define threshold for intentional scroll
    const scrollThresholdPercent = 10; // Threshold as a percentage of the viewport height
    const scrollThreshold = window.innerHeight * (scrollThresholdPercent / 100);
    const currentPosition = window.scrollY;                                        //IS SCROLLY FINE?, I USED THAT SINCE PREVIOUS WAS DEPRECATED
    const maxScroll = document.body.scrollHeight - window.innerHeight;

    if (currentPosition > scrollThreshold && isFirstPage) {
        handleFindOutMoreClick();                                                   //FIX THE PERMISSIONS OF THIS LATER
    } else if (currentPosition < maxScroll - scrollThreshold && !isFirstPage) {
        handleReturnToCameraClick();
    }
  };

  useEffect(() => {
    const options = { passive: false };
    window.addEventListener('scroll', handleScroll, options);

    return () => {
      window.removeEventListener('scroll', handleScroll, options);
    };
  }, [isFirstPage, showObjectDetails]);

  useEffect(() => {
    const checkInferenceStatus = () => {
      if (videoCaptureRef.current) {
        const currentIsInferenceRunning = videoCaptureRef.current.isInferenceRunning;
        setIsInferenceRunning(currentIsInferenceRunning);

        // Only start the timer if the camera is ready
        if (videoCaptureRef.current.isCameraReady) {
          if (!currentIsInferenceRunning) {
            const lastInferenceTime = videoCaptureRef.current.getLastInferenceTime();
            const timeSinceLastInference = Date.now() - lastInferenceTime;
            setHasInferenceStoppedLong(timeSinceLastInference > 7000);
          } else {
            setHasInferenceStoppedLong(false);
          }
        } else {
          // Reset the timer if the camera is not ready
          setHasInferenceStoppedLong(false);
        }
      }
    };

    const intervalId = setInterval(checkInferenceStatus, 1000);

    return () => clearInterval(intervalId);
  }, []);

  



  const handleFirstInferenceComplete = useCallback(() => {
    setIsFirstInferenceComplete(true);
  }, []);

  const handleConsent = () => {
    onConsent();
  };

  useEffect(() => {
    if (!isFirstInferenceComplete) {
      const timer = setTimeout(() => {
        setShowWifiMessage(true);
      }, 10000);

      return () => clearTimeout(timer);
    } else {
      setShowWifiMessage(false);
    }
  }, [isFirstInferenceComplete]);

  return (
    <>
        {/* THE BELOW CODE IS FOR RESTRICTING DESKTOP ACCESS, DIDNT NEED THE <> AROUND THIS BEFORE THIS ADDITION */}
        <div className="desktop-message">
          <h2>Mobile Only</h2>
          <p>This application is designed for mobile devices only. Please access it from your smartphone.</p>
        </div>    
        {/* THE ABOVE CODE IS FOR RESTRICTING DESKTOP ACCESS, DIDNT NEED THE <> AROUND THIS BEFORE THIS ADDITION */}
        
        <div className="object-page-container" style={{ height: `${height}px` }}>
        
        {/* {isOverlayVisible && (
          <div
            className="full-screen-overlay"
            onClick={handleOverlayClick}
          />
        )} */}

        <NavFromObject 
          userId={userId}
          objectId={objectId}
        />
            
        <div ref={videoContainerRef} className="video-container">
          <VideoCapture 
            ref={videoCaptureRef} 
            handleResponseTitleChange={handleResponseTitleChange}
            onFirstInferenceComplete={handleFirstInferenceComplete}
            userId={userId}
          />
        </div>

        {isFirstPage && (
          <div className="white-box">
          </div>
        )}  

        {showObjectDetails ? (

          <div ref={conversationRef} className={`scroll-container ${isFirstPage ? 'first-view' : 'second-view'}`}>

            {/* <ObjectSummary
              responseTitle={responseTitle}
              responseSummary={responseSummary}
              thumbnailImage={thumbnailImage}
              handleFindOutMoreClick={handleFindOutMoreClick}
              handleReturnToCameraClick={handleReturnToCameraClick}
              isFirstPage={isFirstPage}
            />         */}
            {/* <div ref={conversationRef} className="object-conversation-container"> */}
              <ObjectConversation 
                responseTitle={responseTitle}
                responseSummary={responseSummary}
                thumbnailImage={thumbnailImage}
                handleFindOutMoreClick={handleFindOutMoreClick}
                handleReturnToCameraClick={handleReturnToCameraClick}
                isFirstPage={isFirstPage}
                responsePrompt={responsePrompt}
                isAudioEnabled={isAudioEnabled}
                setIsAudioEnabled={setIsAudioEnabled}
                userLanguage={userLanguage} 
                userType={userType}
                globalAudio={globalAudio.current} 
                isRecording={isRecording} 
                setIsRecording={setIsRecording}
                openInput={openInput}
                additionalInstructions={additionalInstructions}
                objectSubtitle={objectSubtitle}
                objectId={objectId}
                userId={userId}
              />
            {/* </div> */}
            
          </div>
        ) : (
          <>
          
          <div className="Object-summary-not-scanned">
            {!isFirstInferenceComplete ? (
              <>
                <div className="loading-scanner-message">
                  Loading app...
                </div>
                <div className="loading-scanner-subtitle">
                  Just a moment while we set things up
                  {showWifiMessage && (
                    <div style={{ fontStyle: 'italic', fontSize: '0.9em', marginTop: '8px', color: '#C3202F' }}>
                      Make sure you are connected to our Wi-Fi
                    </div>
                  )}
                </div>
                <div className="loading-scanner-spinner">
                  <div className="loading-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </>
            ) : !hasConsented ? (
              <div className="consent-button-container">
                <button className="consent-button" onClick={handleConsent}>
                  Agree and continue <span className="arrow">→</span>
                </button>
              </div>
            ) : (
              <>
                <div className="no-object-message">
                  Scan any wax figure when ready!
                </div>
                <div className="no-object-image scan-figure">
                  <img src="/wax_figure_scan.png" alt="Scan a wax figure" />
                </div>
              </>
            ) }
            
            {/*             
              !hasInferenceStoppedLong ? 
                : (
                  <>
                    <div className="no-object-message">
                      Sorry, scanner not working, please refresh this page.
                    </div>
                    <div className="no-object-image scanner-not-working">
                      <img src="/not_working_scan.png" alt="Scanner not working" />
                    </div>
                  </>
                )} */}


            {/* Commented out Lottie animation
            <div className="no-object-animation">
              <Lottie options={defaultOptionsScan} />
            </div>
            <div className="no-object-message">
              Scan an object when ready!
            </div>
            */}
          </div>
          </>
        )}
      </div>
    </>
  );
};

export default ObjectPage;
