import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; //removed Link from here for now, maybe need it later
import Object from './Pages/Object';
import Personalisation from './Pages/Personalisation';
import ConsentPopup from './Components/ConsentPopup';
import { v4 as uuidv4 } from 'uuid';
import ExpiryFeedback from './Components/ExpiryFeedback';

function App() {
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem('userLanguage') || 'English');
  const [userType, setUserType] = useState(localStorage.getItem('userType') || 'adult');
  const [openInput, setOpenInput] = useState(localStorage.getItem('openInput') || '');
  const [showConsent, setShowConsent] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [isExpired, setIsExpired] = useState(false);
  const [hasConsented, setHasConsented] = useState(localStorage.getItem('hasConsented') === 'true');

  useEffect(() => {
    if (!userId) {
      const newUserId = uuidv4();
      setUserId(newUserId);
      localStorage.setItem('userId', newUserId);
    }
  }, []);
  
  useEffect(() => {
    const hasConsented = localStorage.getItem('hasConsented');
    if (hasConsented !== 'true') {
      setShowConsent(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem('hasConsented', 'true');
    setHasConsented(true);
  };

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    localStorage.setItem('userLanguage', language);
    setUserLanguage(language);
  };

  useEffect(() => {
    // Prevent zoom on double tap
    document.addEventListener('touchstart', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

    // Prevent zoom on pinch
    document.addEventListener('touchmove', (event) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    }, { passive: false });

    // Prevent zoom on double tap
    let lastTouchEnd = 0;
    document.addEventListener('touchend', (event) => {
      const now = (new Date()).getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    }, { passive: false });

    // Prevent zoom using keyboard shortcuts or mouse wheel
    document.addEventListener('keydown', (event) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
        event.preventDefault();
      }
    });

    document.addEventListener('wheel', (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    }, { passive: false });
  }, []);

  
  //// COMMENT OUT BELOW WHEN TESTING ////
  useEffect(() => {
    // Check if this is the first visit
    const initialAccessTime = localStorage.getItem('initialAccessTime');
    if (!initialAccessTime) {
      localStorage.setItem('initialAccessTime', Date.now().toString());
    }

    // Function to check if session is expired
    const checkExpiration = () => {
      const initialTime = localStorage.getItem('initialAccessTime');
      if (initialTime) {
        const timeElapsed = Date.now() - parseInt(initialTime);
        const timeout = 60 * 60 * 1000;
        
        if (timeElapsed > timeout) {
          setIsExpired(true);
        }
      }
    };

    checkExpiration();

    const intervalId = setInterval(checkExpiration, 10000);

    return () => clearInterval(intervalId);
  }, []);

  if (isExpired) {
    return <ExpiryFeedback userId={userId} />;
  }
  ////COMMENT OUT ABOVE WHEN TESTING ////

  
  return (
    <Router>
      {/* <nav> */}
        {/* Navigation links to different routes */}
        {/* <Link to="/scan">Scan</Link> */}
        {/* <Link to="/personalisation">Personalisation</Link> */}
        {/* <Link to="/conversation">Conversation</Link> */} 
      {/* </nav> */}
      {/* {showConsent && (
        <ConsentPopup 
          userLanguage={userLanguage}
          handleLanguageChange={handleLanguageChange}
          onConsent={handleConsent}
        />
      )} */}
      {!hasConsented && <ConsentPopup />}
      <Routes>
        <Route exact path="/" element={<Object userLanguage={userLanguage} userType={userType} openInput={openInput} userId={userId} onConsent={handleConsent} hasConsented={hasConsented} />} />
        <Route path="/scan" element={<Object userLanguage={userLanguage} userType={userType} openInput={openInput} userId={userId} onConsent={handleConsent} hasConsented={hasConsented} />} />
        <Route path="/personalisation" element={<Personalisation userLanguage={userLanguage} userType={userType} setUserLanguage={setUserLanguage} setUserType={setUserType} openInput={openInput} setOpenInput={setOpenInput} />} />
      </Routes>
    </Router>
  );
}

export default App;
