import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PopupStyles.css';

const SurveyPopup = ({ onClose, userId, location }) => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleOverlayClick = (e) => {
    // Only close if the click is directly on the overlay
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    const feedbackData = {
      questions: [
        {
          question: "How much did you enjoy this digital guide?",
          answer: rating,
          type: "rating"
        },
        {
          question: "Please share any other feedback you have for us:",
          answer: feedback,
          type: "text"
        }
      ],
      metadata: {
        userId: userId,
        location: location,
        timestamp: new Date().toISOString()
      }
    };

    // Set submitted state immediately
    setSubmitted(true);

    // Fire and forget the fetch
    fetch('https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/mt-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedbackData)
    }).catch(error => {
      console.error('Error submitting feedback:', error);
    });
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="popup-title">Your Feedback</h2>
        
        <div className="survey-question">
          <p>How much did you enjoy this digital guide?</p>
          <div className="rating-container">
            <div className="rating-labels">
              <span className="rating-label">Not at all</span>
              <span className="rating-label">Loved it!</span>
            </div>
            <div className="rating-buttons">
              {[0, 1, 2, 3, 4, 5].map((value) => (
                <button
                  key={value}
                  className={`rating-button ${rating === value ? 'selected' : ''}`}
                  onClick={() => setRating(value)}
                >
                  {value}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="survey-question">
          <p>Please share any other feedback you have for us:</p>
          <textarea
            className="feedback-textarea"
            placeholder="Tell us what you enjoyed or what could be improved..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>

        <button 
          className="popup-button primary submit-button" 
          onClick={handleSubmit}
          disabled={rating === null && feedback.trim() === ''}
        >
          Submit Feedback
        </button>
        
        {submitted && (
          <p className="thank-you-message">Thank you for your feedback!</p>
        )}
      </div>
    </div>
  );
};

export default SurveyPopup;