import React, { useState } from 'react';

//THE BELOW IS WHAT IVE COMMENTED OUT FOR THE TRIALS
// import { useNavigate } from 'react-router-dom';  //USE THIS WHEN NEED TO PROGRAMMATIC NAVIGATE (does it still avoid new requests to server?)
// import personImage from '../assets/Person.png';
// import { faMap } from '@fortawesome/free-solid-svg-icons';

// import { Link } from 'react-router-dom';  //USE THIS IF DOING DIRECT LINK

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import AIInfoPopup from './AIInfoPopup';
import SurveyPopup from './SurveyPopup';

const NavFromObjecct = ({ userId }) => {

    //THE BELOW IS WHAT IVE COMMENTED OUT FOR THE TRIALS
    // const navigate = useNavigate();

    // const Nav2Personalisation = () => {
    //     navigate('/personalisation'); // Navigate to the Personalisation page
    //   };
    
    const [showAIInfo, setShowAIInfo] = useState(false);
    const [showSurvey, setShowSurvey] = useState(false);
    const location = process.env.REACT_APP_LOCATION;
  
    const handleSurveyContinue = () => {
      window.open('YOUR_SURVEY_URL', '_blank');
      setShowSurvey(false);
    };  

    return (
        <>
            <button onClick={() => setShowAIInfo(true)} className="nav2info-button">
                <FontAwesomeIcon icon={faInfo} className="info-icon" style={{color: "#ffffff"}} />
            </button>
            
            {/* DISABLE UNTIL READY */}
            <button onClick={() => setShowSurvey(true)} className="nav2survey-button">
                <FontAwesomeIcon icon={faClipboardList} className="survey-icon" style={{color: "#ffffff"}} />
            </button>

            {showAIInfo && <AIInfoPopup onClose={() => setShowAIInfo(false)} />}
            {showSurvey && (
                <SurveyPopup 
                onClose={() => setShowSurvey(false)}
                userId={userId}
                location={location}
                />
            )}
            
            {/* <button onClick={Nav2Personalisation} className="nav2personalisation-button">
                <img src={personImage} alt="Person" className="person-nav-icon" />
            </button>
        
            <button className="nav2map-button">
                <FontAwesomeIcon icon={faMap} className="map-icon" style={{color: "#ffffff",}} />
            </button> */}
        </>
    );
};

export default NavFromObjecct;
