import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './PopupStyles.css';

const ValidationPopup = ({ onClose, validationData }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="popup-title">Validated Information</h2>
        <div className="popup-subtitle">
          This AI-generated response is based on the following verified sources. 
          Please note that any additional information beyond these sources should be independently verified.
        </div>
        <div className="popup-text validation-sources">
          {validationData.relevant_info.map((info, index) => (
            <p key={index} className="source-item">
              <strong>Source {index + 1}:</strong> "{info}"
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValidationPopup;