import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import * as tf from '@tensorflow/tfjs'; //USE IF RUNNING MOBILENET
import { CATEGORIES, TITLES } from '../config'; 
// import axios from 'axios';  //USE IF RUNNING CV LOCALLY

const VideoCapture = forwardRef(({ handleResponseTitleChange, onFirstInferenceComplete, userId }, ref) => {
    // const ipAddress = process.env.REACT_APP_IP_ADDRESS; //LOCAL VERSION
    // const ipAddressAWSIR = process.env.REACT_APP_AWS_IR_API_ENDPOINT;  //AWS VERSION
    const [model, setModel] = useState(null); //MOBILENET VERSION
    //above is 20240924 version
    // Version without RS Cabinet = ['1.0', '10.0', '11.0', '12.0', '13.0', '14.0', '15.0', '16.0', '17.0', '18.0', '19.0', '2.0', '20.0', '21.0', '22.0', '23.0', '24.0', '25.0', '26.0', '27.0', '28.0', '29.0', '3.0', '30.0', '31.0', '32.0', '33.0', '34.0', '35.0', '36.0', '37.0', '38.0', '39.0', '4.0', '40.0', '41.0', '42.0', '43.0', '43.1', '43.2', '43.3', '43.4', '43.5', '43.6', '43.7', '43.8', '44.0', '45.0', '46.0', '47.0', '48.0', '49.0', '5.0', '50.0', '51.0', '52.0', '53.0', '54.0', '55.0', '56.0', '57.0', '58.0', '59.0', '6.0', '60.0', '61.0', '62.0', '63.1', '63.2', '63.3', '63.4', '63.5', '63.6', '63.7', '64.0', '65.0', '66.0', '67.0', '68.0', '69.0', '7.0', '70.0', '71.0', '72.0', '73.0', '74.0', '75.0', '76.1', '76.2', '76.3', '8.0', '9.0', '_other'];
    const videoRef = useRef(null);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const [zoomLevel, setZoomLevel] = useState('Unknown');
    const [additionalZoom, setAdditionalZoom] = useState(1);
    const [scaleFactor, setScaleFactor] = useState(1);
    // const [processedImageSrc, setProcessedImageSrc] = useState(''); // FOR TESTING IMAGE

    // const [lastDetectedObject, setLastDetectedObject] = useState(null);
    const lastDetectedObjectRef = useRef(null);
    const dummyCallMadeRef = useRef(false); // useRef instead of useState
    const [cameraError, setCameraError] = useState(null);
    const [modelLoadingStatus, setModelLoadingStatus] = useState('Not started');
    const modelLoadStartTime = useRef(null);
    const [initializationTimeoutReached, setInitializationTimeoutReached] = useState(false);
    const [isInferenceRunning, setIsInferenceRunning] = useState(true);
    const lastInferenceTimeRef = useRef(Date.now());
    const [isWaitingForPermission, setIsWaitingForPermission] = useState(false);
    const [cameraPermissionDenied, setCameraPermissionDenied] = useState(false); // New state for permission denial
    const [isPaused, setIsPaused] = useState(false);
    const firstInferenceCompleted = useRef(false);
    const appStartTime = useRef(Date.now());
    const location = process.env.REACT_APP_LOCATION;
    const [isCameraPermissionGranted, setIsCameraPermissionGranted] = useState(false);
    const [showFinalErrorMessage, setShowFinalErrorMessage] = useState(false);

    //MOBILENET
    useEffect(() => {
        const loadModel = async () => {
            const startLoadTime = Date.now();
            console.log(`[Timing] App start -> Model load start: ${startLoadTime - appStartTime.current}ms`);
            console.log("[Timing] Starting model load...");
            setModelLoadingStatus('Loading');
            modelLoadStartTime.current = startLoadTime;
            console.log("Model URL:", process.env.REACT_APP_MODEL_URL);
            
            try {
                const model = await tf.loadLayersModel(process.env.REACT_APP_MODEL_URL);
                const loadCompleteTime = Date.now();
                const loadDuration = loadCompleteTime - startLoadTime;
                console.log(`[Timing] Model load duration: ${loadDuration}ms`);
                console.log(`[Timing] App start -> Model load complete: ${loadCompleteTime - appStartTime.current}ms`);
                
                // Run dummy inference immediately after model loads
                console.log("[Timing] Starting warmup inference...");
                const warmupStartTime = Date.now();
                
                // Create a dummy tensor with the correct shape
                const dummyTensor = tf.zeros([1, 224, 224, 3]);
                await model.predict(dummyTensor).data();
                dummyTensor.dispose();

                const warmupDuration = Date.now() - warmupStartTime;
                console.log(`[Timing] Warmup inference completed in ${warmupDuration}ms`);
                
                // Set model ready state after warmup
                setModelLoadingStatus('Loaded');
                setModel(model);
                
                // Notify parent that first inference is complete
                if (!firstInferenceCompleted.current) {
                    firstInferenceCompleted.current = true;
                    onFirstInferenceComplete();
                }

            } catch (error) {
                console.error("Error loading model or running warmup:", error);
                setModelLoadingStatus('Error');
            }
        };
        
        loadModel();
    }, []);
    
    // Function to start the camera
    const startCamera = useCallback(async () => {
        console.log("Attempting to start camera...");
        setInitializationTimeoutReached(false);
        setIsWaitingForPermission(true);

        try {
            // If we have a paused stream, just resume it
            if (isPaused && videoRef.current && videoRef.current.srcObject) {
                videoRef.current.play();
                setIsPaused(false);
                setIsCameraReady(true);
                return;
            }

            // Otherwise, request a new stream
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment',
                    width: { ideal: 2560 },
                    height: { ideal: 1920 },
                    zoom: { ideal: 0.5 },
                }
            });
            console.log("Camera stream obtained successfully");
            setIsWaitingForPermission(false);

            // Listen for the stream ending (e.g., permission revoked)
            stream.getVideoTracks()[0].addEventListener('ended', () => {
                console.log("Camera stream ended, permission may have been revoked.");
                setCameraPermissionDenied(true); // Update state to reflect permission denial
                stopCamera(); // Stop the camera
            });

            // Start the initialization timeout only after permission is granted
            const initializationTimeout = setTimeout(() => {
                setInitializationTimeoutReached(true);
            }, 5000);

            videoRef.current.srcObject = stream;
            await initCameraSettings(stream);
            setTimeout(() => {
                setIsCameraReady(true);
                console.log("Camera ready");
                clearTimeout(initializationTimeout); // Clear the timeout if camera becomes ready
            }, 850); //CHANGED FROM 1500 TO 800, to 200 TO TEST IF IT CAN BE FASTER - THIS IS NOT INFERENCE TIME!!!!
        } catch (error) {
            console.error("Error accessing camera:", error);
            setCameraError(error.message);
            setIsWaitingForPermission(false);
        }
    }, [isPaused]);

    // Function to initialize camera settings
    async function initCameraSettings(stream) {
        console.log("Initializing camera settings...");
        const videoTrack = stream.getVideoTracks()[0];
        const capabilities = videoTrack.getCapabilities && videoTrack.getCapabilities();
        if (capabilities && capabilities.zoom) {
            const minZoom = capabilities.zoom.min;
            await videoTrack.applyConstraints({ advanced: [{ zoom: minZoom }] });
            console.log(`Applied minimum zoom: ${minZoom}`);
        }
        const settings = videoTrack.getSettings();
        setZoomLevel(settings.zoom || 'Not Supported');
        setScaleFactor(settings.zoom === 0.5 ? 1.8 : 1);
        console.log("Camera settings initialized:", settings);
    }

    // Function to stop the camera
    function stopCamera(temporary = false) {
        console.log(`[Camera] Stopping camera (${temporary ? 'temporary' : 'full'} stop)`);
        if (videoRef.current && videoRef.current.srcObject) {
            if (temporary) {
                console.log('[Camera] Pausing video element');
                videoRef.current.pause();
                
                // Log active tracks
                const tracks = videoRef.current.srcObject.getTracks(); //BELIEVE THIS LINE IS ONLY FOR LOGGING PURPOSES
                console.log('[Camera] Active tracks:', tracks.map(t => ({
                    kind: t.kind,
                    enabled: t.enabled,
                    readyState: t.readyState
                })));
                
                setIsPaused(true);
            } else {
                // Full stop - only for component unmount
                console.log('[Camera] Full stop - releasing all tracks');
                const tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => {
                    console.log(`[Camera] Stopping track: ${track.kind}`);
                    track.stop();
                });
                videoRef.current.srcObject = null;
            }
        }
    }
    // Clean up on unmount only
    useEffect(() => {
        return () => stopCamera(false); // full stop on unmount
    }, []);

    // Expose methods to parent
    useEffect(() => {
        ref.current = {
            startCamera,
            stopCamera: () => stopCamera(true), // temporary stop by default
            getVideoElement: () => videoRef.current,
        };
    }, [ref, startCamera]);

    // Function to capture and send image
    async function captureAndProcessImage() {
        const inferenceStartTime = Date.now();
        console.log(`[Timing] Starting inference cycle at ${inferenceStartTime - appStartTime.current}ms from app start`);
        
        console.log('[Inference] Camera ready:', isCameraReady);
        console.log('[Inference] Video ready state:', videoRef.current?.readyState);
        console.log('[Inference] Is paused:', isPaused);

        if (isPaused) {
            console.log('[Inference] Skipping inference - camera is paused');
            return;
        }

        if (isCameraReady && videoRef.current && videoRef.current.readyState === 4 && model) {  //REMOVE MODEL HERE FOR LOCAL && model
            setIsInferenceRunning(true);
            lastInferenceTimeRef.current = inferenceStartTime;
            
            console.log("Capturing and processing image...");
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const { videoWidth, videoHeight } = videoRef.current;
            const zoomInFactor = (zoomLevel === 0.5 ? 1.8 : 1) * additionalZoom;
            const size = Math.min(videoWidth, videoHeight) / zoomInFactor;
            const startX = (videoWidth - size) / 2;
            const offsetPercentage = -1; // THESE TWO LINES ARE MORE WORK AROUNDS FOR NOW, FOR SOME REASON THE IMAGE SENT TO BACKEND IS A BIT ABOVE THE IMAGE SHOWN TO USERS
            const offsetY = (offsetPercentage / 100) * videoHeight;
            const startY = ((videoHeight - size) / 2) + offsetY;
            canvas.width = size;
            canvas.height = size;
            context.drawImage(videoRef.current, startX, startY, size, size, 0, 0, size, size);

            
            //SENDING FOR BACKEND PROCESSING
            // try {
            //     const response = await axios.post(`${ipAddress}/upload-image`, {  //LOCAL VERSION: `${ipAddress}/upload-image`  //API version: 'https://dxhfap8427.execute-api.eu-west-2.amazonaws.com/ImageRecognitionFunction_Prototype'
            //         image: canvas.toDataURL('image/jpeg')
            //     });
            //     handleResponseTitleChange(
            //         response.data.object_title,
            //         response.data.prompt_data,
            //         response.data.summary_data,
            //         response.data.thumbnail_image
            //     );
            //     // setProcessedImageSrc(response.data.processed_image); // TESTING FOR PROCESSED IMAGE

            // } catch (error) {
            //     console.error("Error sending image:", error);
            // }
            
            //MOBILENET PROCESSING
            // Log the canvas content for debugging
            tf.tidy(() => {
                const tensorStartTime = Date.now();
                console.log(`[Timing] Starting tensor processing at ${tensorStartTime - appStartTime.current}ms from app start`);

                const imageTensor = tf.browser.fromPixels(canvas)
                    .resizeNearestNeighbor([224, 224])
                    .toFloat()
                    .div(255.0) 
                    .expandDims();

                // console.log("Image tensor created, starting prediction...");
                // const startTime = Date.now();

                (async () => {
                    try {
                        setIsInferenceRunning(true);
                        console.log("-----------------------------");
                        const predictionStartTime = Date.now();
                        console.log(`[Timing] Starting prediction at ${predictionStartTime - appStartTime.current}ms from app start`);
                        
                        const predictions = await model.predict(imageTensor).data();
                        const predictionEndTime = Date.now();
                        
                        console.log(`[Timing] Prediction duration: ${predictionEndTime - predictionStartTime}ms`);
                        console.log(`[Timing] Total inference cycle duration: ${predictionEndTime - inferenceStartTime}ms`);
                        console.log(`[Timing] App start -> First prediction: ${predictionEndTime - appStartTime.current}ms`);

                        // After successful inference, check if it's the first one
                        // if (!firstInferenceCompleted.current) {
                        //     firstInferenceCompleted.current = true;
                        //     onFirstInferenceComplete(); // Notify parent component
                        // }
    
                        // Log the predictions array for debugging
                        // console.log("Predictions array:", predictions);

                        // NEW TOP 5 PREDICTIONS, COMMENT OUT IF DESIRED
                        // Create an array of predictions with their category, id, and likelihood
                        const predictionsArray = Array.from(predictions).map((probability, index) => ({
                            id: CATEGORIES[index],
                            title: TITLES[index], // You can map these to more descriptive titles if needed
                            likelihood: (probability * 100).toFixed(2) // Convert to percentage
                        }));

                        // Sort the predictions by likelihood in descending order
                        const sortedPredictions = predictionsArray.sort((a, b) => b.likelihood - a.likelihood);

                        // Get the top 5 predictions
                        const topPredictions = sortedPredictions.slice(0, 5);

                        // Log only the top 5 predictions
                        console.log("Top 5 predictions:");
                        topPredictions.forEach(prediction => {
                            console.log(`Title: ${prediction.title}, ID: ${prediction.id}, Likelihood: ${prediction.likelihood}%`);
                        });
                        //ABOVE IS FOR LOGGING TOP 5, COMMENT OUT IF DESIRED


                        const topPredictionIndex = predictions.indexOf(Math.max(...predictions));
                        let topPredictionCategory = CATEGORIES[topPredictionIndex];
                        const topPredictionProbability = predictions[topPredictionIndex];

                        // If the top prediction's probability is below 50%, set it to '_other', I GUESS MAYBE INEFFICIENT IF OTHER IS BELOW 50%
                        if (topPredictionProbability < 0.97) {
                            console.log("Prediction below 97%, classifying as '_other'");
                            topPredictionCategory = '_other';
                        }
                                                
                        console.log("Top prediction:", topPredictionCategory);
                        // console.log("All predictions:", allPredictions);
                        
                        //UNCOMMENT BELOW IF WANT TO SEND PREDICTIONS TO LOGLY
                        // const predictionsArray = Array.from(predictions); // Convert the tensor into a regular array
                        // console.log(predictionsArray);
                        // console.log("Sending predictions to loggly");

                        // const categoryNameMapping = {
                        
                        // };                    

                        // const sortedPredictions = predictionsArray
                        //     .map((prob, idx) => ({
                        //         category: CATEGORIES[idx],
                        //         categoryName: categoryNameMapping[CATEGORIES[idx]] || CATEGORIES[idx], // Fetch category name or fall back to category ID
                        //         probability: (prob * 100).toFixed(2) // Convert to percentage and format to 2 decimal places
                        //     }))
                        //     .sort((a, b) => b.probability - a.probability); // Sort from most likely to least likely

                        // try {
                        //     await fetch("https://logs-01.loggly.com/inputs/62d873d8-5068-4780-a91a-7394a311397e/tag/http/", {
                        //         method: 'POST',
                        //         headers: {
                        //             'Content-Type': 'application/json'
                        //         },
                        //         body: JSON.stringify({
                        //             message: "Prediction data",
                        //             top_prediction: topPredictionCategory,
                        //             all_predictions: sortedPredictions
                        //         })
                        //     });
                        //     console.log("Predictions sent to Loggly.");
                        // } catch (logError) {
                        //     console.error("Error sending logs to Loggly:", logError);
                        // }
                        //REMOVE ABOVE IS WANT TO STOP LOGGLY
    
                        // Log the top prediction index and category for debugging
                        // console.log("Top prediction index:", topPredictionIndex);
                        // console.log("Top prediction category:", topPredictionCategory);
                        // console.log(`top prediction is going to be (${topPredictionCategory}) and the last is going to be (${lastDetectedObject})`);

                        //UNCOMMENT BELOW IF WANT TO STOP WARMUP//    
                        // if (topPredictionCategory !== lastDetectedObject && topPredictionCategory !== '_other') {
                        //     setLastDetectedObject(topPredictionCategory);
                        //     console.log(`New object detected (${topPredictionCategory}), sending to backend.`);

                        //     // Call backend with the detected object ID
                        //     try {
                        //         const response = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/summary-data`, {  //Using the Rest API, can also use HTTP API
                        //             method: 'POST',
                        //             headers: {
                        //                 'Content-Type': 'application/json'
                        //             },
                        //             body: JSON.stringify({ Object_ID: topPredictionCategory })
                        //         });

                        //         const data = await response.json();
                        //         handleResponseTitleChange(
                        //             data.object_title,
                        //             data.prompt_data,
                        //             data.summary_data,
                        //             data.thumbnail_image
                        //         );
                        //     } catch (error) {
                        //         console.error("Error sending object ID to backend:", error);
                        //     }
                        // }
                        //UNCOMMENT ABOVE IF WANT TO STOP WARMUP//

                        //UNCOMMENT BELOW IF WANT TO WARMUP// 
                        // Handle dummy call on the first detection of '_other'
                        if (topPredictionCategory === '_other') {
                            if (!dummyCallMadeRef.current) {
                                console.log("Making dummy calls to backend for cold start prevention.");
                                dummyCallMadeRef.current = true;

                                try {
                                    const MTSummaryResponse = await fetch(process.env.REACT_APP_SUMMARY_DATA_ENDPOINT, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ 
                                            Object_ID: topPredictionCategory,
                                            userId: userId,
                                            location: location,
                                            dummy: true
                                        })
                                    });
                                    console.log("Dummy summary data call response:", MTSummaryResponse.status); // Log response status

                                    const MTTextGeneration = await fetch(process.env.REACT_APP_TEXT_GENERATION_ENDPOINT, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ 
                                            dummy: true,
                                            location: location,
                                            userId: userId
                                        })
                                    });
                                    console.log("DummyRLM text generation call response:", MTTextGeneration.status); // Log response status

                                    //SWITCH BETWEEN ELEVENLABS AND POLLY HERE//
                                    // const ttsResponse = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/tts-el`, {     //ELEVENLABS VERSION
                                    const ttsResponse = await fetch(`https://kjgw2i5dhh.execute-api.eu-west-2.amazonaws.com/TextToSpeechFunction_Prototype`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ 
                                            dummy: true,
                                            location: location,
                                            userId: userId
                                        })
                                    });
                                    console.log("Dummy text to speech call response:", ttsResponse.status); // Log response status

                                    const validationResponse = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/validation`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ 
                                            dummy: true,
                                            location: location,
                                            userId: userId
                                        })
                                    });
                                    console.log("Dummy validation call response:", validationResponse.status); // Log response status

                                    const speechToTextResponse = await fetch(`https://i6569suty5.execute-api.eu-west-2.amazonaws.com/prototype/speech-to-text`, {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({ 
                                            dummy: true,
                                            location: location,
                                            userId: userId
                                        })
                                    });
                                    console.log("Dummy speech to text call response:", speechToTextResponse.status); // Log response status

                                } catch (error) {
                                    console.error("Error making dummy call:", error);
                                }
                            } else {
                                console.log("Detected '_other' but dummy call already made. Skipping backend call.");
                            }
                        } else if (topPredictionCategory !== lastDetectedObjectRef.current) {
                            // setLastDetectedObject(topPredictionCategory);
                            lastDetectedObjectRef.current = topPredictionCategory; // Update the ref directly
                            
                            // console.log(`Sending detected object (${topPredictionCategory}) to backend.`);
                            // console.log(`top prediction is (${topPredictionCategory}) and last was (${lastDetectedObjectRef.current})`);

                            try {
                                const response = await fetch(process.env.REACT_APP_SUMMARY_DATA_ENDPOINT, {  //THIS IS A DUMMY, REMOVED 'a' FROM THE END // IS IT ALWAYS A DUMMY??? I THINK THIS NEEDS TO BE CORRECT?
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ 
                                        Object_ID: topPredictionCategory,
                                        userId: userId,
                                        location: location
                                    })
                                });

                                const data = await response.json();
                                // console.log("Received prompt_data:", data.prompt_data); // Add this line to see prompt_data

                                // Log the entire response data
                                // console.log("Backend response:", data);

                                // Log individual fields
                                // console.log("Object title:", data.object_title);
                                // console.log("Prompt data:", data.prompt_data);
                                // console.log("Summary data:", data.summary_data);
                                // console.log("Thumbnail image:", data.thumbnail_image);
                                // console.log("Additional instructions:", data.additional_instructions);

                                handleResponseTitleChange(
                                    data.object_title,
                                    data.prompt_data,
                                    data.summary_data,
                                    data.thumbnail_image,
                                    data.additional_instructions,
                                    data.object_subtitle,
                                    topPredictionCategory
                                );
                            } catch (error) {
                                console.error("Error sending object ID to backend:", error);
                            }
                        } else {
                            console.log(`Same object detected (${topPredictionCategory}), not sending to backend.`);
                        }
                        //UNCOMMENT ABOVE IF WANT TO WARMUP//

                        // After successful inference
                        lastInferenceTimeRef.current = Date.now();
                        setIsInferenceRunning(false);
                    } catch (error) {
                        console.error("Error during prediction:", error);
                    } finally {
                        setIsInferenceRunning(false);
                    }
                })();
            });
        } else {
            console.log("[Timing] Skipping image capture - conditions not met:", {
                isCameraReady,
                videoReady: videoRef.current?.readyState === 4,
                modelLoaded: !!model,
                timeFromStart: Date.now() - appStartTime.current
            });
        }
    }

    // Keep only this useEffect for inference
    useEffect(() => {
        let captureInterval;
        let isProcessing = false; // Move outside setInterval to ensure it persists between intervals
        
        if (isCameraReady && !isPaused && model) {
            const effectTime = Date.now();
            console.log(`[Timing] Inference useEffect triggered: ${effectTime - appStartTime.current}ms`);
            console.log('[Timing] Starting capture interval - Camera ready:', isCameraReady, 'Model loaded:', !!model, 'Paused:', isPaused);

            // Run first inference immediately with protection
            (async () => {
                if (!isProcessing) {
                    isProcessing = true;
                    try {
                        await captureAndProcessImage();
                    } finally {
                        isProcessing = false;
                    }
                }
            })();
            
            // Add protection against concurrent executions
            captureInterval = setInterval(async () => {  //NOT SURE THIS ISPROCESSING ACTUALLY HELPS, BUT MAKES SENSE THAT IT SHOULD HELP STOP A BACKLOG BUILDING, CHECK LATER IF IT IS ACTUALLY CAUSING IT TO GET SLOWER
                if (isProcessing) {
                    console.log('[Timing] Skipping inference - previous one still running');
                    return;
                }
                isProcessing = true;
                try {
                    await captureAndProcessImage();
                } catch (error) {
                    console.error('Error in inference cycle:', error);
                    // Maybe add some error handling here
                } finally {
                    isProcessing = false;
                }
            }, 1000); //INTERVAL SET HERE FOR INFERENCE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        } else {
            console.log('[Timing] Not starting interval - Camera ready:', isCameraReady, 'Model loaded:', !!model, 'Paused:', isPaused);
        }
        return () => {
            if (captureInterval) {
                // console.log('[Inference] Cleaning up interval');
                clearInterval(captureInterval);
                isProcessing = false; // Reset processing flag on cleanup
            }
        };
    }, [isCameraReady, isPaused, zoomLevel, additionalZoom, model]);

    //UNCOMMENT BELOW TO NOT USE COOKIES AND LOCAL STORAGE//
    // Start the camera when the component mounts
    // useEffect(() => {
    //     startCamera();
    //     return () => stopCamera();
    // }, []);
    //UNCOMMENT ABOVE TO NOT USE COOKIES AND LOCAL STORAGE//

    //UNCOMMENT BELOW TO USE COOKIES AND LOCAL STORAGE//

    function setCookie(name, value, days) {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
    }
    
    function getCookie(name) {
        return document.cookie.split('; ').reduce((r, v) => {
            const parts = v.split('=');
            return parts[0] === name ? decodeURIComponent(parts[1]) : r;
        }, '');
    }
    

    useEffect(() => {
        const checkPermissions = async () => {
            // First check for permission in cookies
            const savedPermissionCookie = getCookie('cameraPermission');
            console.log("Saved permission in cookies:", savedPermissionCookie);
            
            if (savedPermissionCookie === 'granted') {
                console.log("Permission already granted (from cookie), starting camera...");
                startCamera();
            } else {
                const savedPermission = localStorage.getItem('cameraPermission');
                console.log("Saved permission in localStorage:", savedPermission);
    
                if (savedPermission === 'granted') {
                    console.log("Permission already granted (from localStorage), starting camera...");
                    startCamera();
                } else {
                    try {
                        console.log("Requesting camera permission...");
                        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                        console.log("Camera permission granted.");
                        
                        // Stop the stream after getting permission
                        stream.getTracks().forEach(track => track.stop());
                        
                        // Save permission status to localStorage and cookies
                        localStorage.setItem('cameraPermission', 'granted');
                        setCookie('cameraPermission', 'granted', 7);  // cookie valid for 7 days
                        
                        // Start the camera
                        startCamera();
                    } catch (error) {
                        console.error("Error accessing camera:", error);
                        setCameraPermissionDenied(true); // Set permission denied state
                        // Save permission status to localStorage and cookies as denied
                        localStorage.setItem('cameraPermission', 'denied');
                        setCookie('cameraPermission', 'denied', 7);
                    }
                }
            }
        };
    
        checkPermissions();
    
        return () => stopCamera();
    }, []);
    //UNCOMMENT ABOVE TO USE COOKIES AND LOCAL STORAGE//
    
    
    // Update the scale transform of the video element
    useEffect(() => {
        if (videoRef.current) {
            const scale = scaleFactor * additionalZoom;
            videoRef.current.style.transform = `scale(${scale})`;
        }
    }, [scaleFactor, additionalZoom]);

    // Add a refresh function
    const refreshCamera = useCallback(() => {
        console.log("Refreshing camera...");
        stopCamera();
        setTimeout(() => {
            startCamera();
        }, 1000);
    }, [startCamera]);

    
    // Check if inferences are running
    useEffect(() => {
        const checkInferenceStatus = () => {
            if (videoRef.current) {
                const currentIsInferenceRunning = isInferenceRunning; // Use the state directly
                // Only start the timer if the camera is ready
                if (isCameraReady) {
                    const currentTime = Date.now();
                    const timeSinceLastInference = currentTime - lastInferenceTimeRef.current;
                    if (timeSinceLastInference > 7000) { // Adjust this value as needed
                        setIsInferenceRunning(false);
                    }
                }
            }
        };

        const intervalId = setInterval(checkInferenceStatus, 1500); // Check every second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [isCameraReady, isInferenceRunning]); // Add isCameraReady to the dependency array

    // Expose isInferenceRunning and lastInferenceTime to parent component
    useEffect(() => {
        if (ref.current) {
            ref.current.isInferenceRunning = isInferenceRunning;
            ref.current.getLastInferenceTime = () => lastInferenceTimeRef.current;
            ref.current.isCameraReady = isCameraReady; // Ensure this line is present
        }
    }, [isInferenceRunning, isCameraReady]); // Add isCameraReady to the dependency array

    // Add this effect to check camera permission status
    useEffect(() => {
        const checkCameraPermission = async () => {
            try {
                const permissionStatus = await navigator.permissions.query({ name: 'camera' });
                setIsCameraPermissionGranted(permissionStatus.state === 'granted');
                
                // Listen for permission changes
                permissionStatus.addEventListener('change', (e) => {
                    setIsCameraPermissionGranted(e.target.state === 'granted');
                });
            } catch (error) {
                console.log("Permission query not supported, falling back to stored permission");
                // Fallback to stored permission from cookies/localStorage
                const savedPermission = getCookie('cameraPermission') || localStorage.getItem('cameraPermission');
                setIsCameraPermissionGranted(savedPermission === 'granted');
            }
        };
        
        checkCameraPermission();
    }, []);

    // Add this useEffect to handle the delay only for the final case
    useEffect(() => {
        let timeoutId;
        if (!isCameraPermissionGranted && !cameraError && !cameraPermissionDenied) {
            timeoutId = setTimeout(() => {
                setShowFinalErrorMessage(true);
            }, 4000);
        } else {
            setShowFinalErrorMessage(false);
        }
        
        return () => clearTimeout(timeoutId);
    }, [isCameraPermissionGranted, cameraError, cameraPermissionDenied]);

    return (
        <div>
            <video 
                ref={videoRef} 
                autoPlay 
                playsInline
                muted 
                className={`video-element ${isCameraReady ? "video-visible" : "video-hidden"}`}
                controls={false}  // Add this  //IF THESE AND CSS MEASURES DONT WORK, THERE ARE SOME HS MEASURE TO PROGRAMMATICALLY PLAY THE VIDEO AFTER THE STREAM IS SET
                playsinline="true"  // Add this (redundant but sometimes helps)
                webkit-playsinline="true"  // Add this for older iOS versions
            ></video>
            
            {/* Show specific messages based on conditions */}
            {!isCameraReady && !cameraError && !cameraPermissionDenied ? (
                <div className="loading-message">Loading Camera...</div>
            ) : initializationTimeoutReached && !isCameraReady && !cameraError && !isWaitingForPermission ? (
                <div className="timeout-message">
                    <div>Please refresh this page and ensure camera access is enabled in your browser</div>
                    {/* <div>
                        <button className="refresh-button" onClick={refreshCamera}>Refresh Camera</button>
                    </div> */}
                </div>
            ) : cameraError && !isWaitingForPermission ? (
                <div className="error-message">
                    <div>Please refresh this page and ensure camera access is enabled in your browser</div>
                    {/* <div>
                        <button className="refresh-button" onClick={refreshCamera}>Refresh Camera</button>
                    </div> */}
                </div>
            ) : cameraPermissionDenied ? (
                <div className="error-message">
                    <div>Please refresh this page and ensure camera access is enabled in your browser</div>
                </div>
            ) : !isCameraPermissionGranted ? (
                <div className="error-message">
                    {showFinalErrorMessage && (
                        <div>Please refresh this page and ensure camera access is enabled in your browser</div>
                    )}
                </div>
            ) : (
                <div className="error-message"></div> //KEEPING THIS BLANK FOR NOW UNTIL FIXED "Please refresh the page and ensure camera access is enabled in your browser settings."
            )}
            
            <div className="zoom-buttons">
                {[1, 2, 3].map((zoom) => (
                    <button key={zoom} className={additionalZoom === zoom ? 'active' : 'not-active'} onClick={(e) => setAdditionalZoom(zoom)}>
                        {zoom}x
                    </button>
                ))}
            </div>
            {/* THE BELOW IS USED FOR TESTING */}
            {/* {processedImageSrc && (
                <div className="processed-image-container">
                    <img src={processedImageSrc} alt="Processed" />
                </div>
            )} */}
            <div className="white-box"></div>
        </div>
    );
});

export default VideoCapture;
