import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import './Personalisation.css';
const PersonalisationPage = ({ userLanguage, userType, setUserLanguage, setUserType, openInput, setOpenInput }) => {
    // const handleLanguageChange = (event) => {
    //     setUserLanguage(event.target.value);
    // };
    
    // const handleUserTypeChange = (event) => {
    //     setUserType(event.target.value);
    // };
    const handleLanguageChange = (event) => {
        const language = event.target.value;
        localStorage.setItem('userLanguage', language);
        setUserLanguage(language);
    };
    
    const handleUserTypeChange = (event) => {
        const userType = event.target.value;
        localStorage.setItem('userType', userType);
        setUserType(userType);
    };
    // const navigate = useNavigate();
    // const handleReturnToCamera = () => {
    //     navigate('/'); // Navigates to the root route (camera page)
    // };
    // const handleRequestChange = (event) => {
    //     setOpenInput(event.target.value);
    // };
    useEffect(() => {
        localStorage.setItem('openInput',openInput);
    }, [openInput])
    const handleOpenInputChange = (event) => {
        setOpenInput(event.target.value);
    };
    

    return (
        <div className="personalisation-container">
            
            <h2 className="personalisation-header">Personalise your experience!</h2>
            <div className="personalisation-form">
                <label className="personalisation-label">
                    Choose your language:
                    <select className="personalisation-select" onChange={handleLanguageChange} value={userLanguage}>
                        <option value="English">English</option>
                        <option value="Welsh">Welsh</option>
                        <option value="French">French</option>
                        <option value="Spanish">Spanish</option>
                        <option value="German">German</option>
                        <option value="Italian">Italian</option>
                    </select>
                </label>
                                
                <label className="personalisation-label">
                    Are you an adult or a child?
                    <select className="personalisation-select" onChange={handleUserTypeChange} value={userType}>
                        <option value="adult">Adult</option>
                        <option value="child">Child</option>
                    </select>
                </label>  
                <label className="personalisation-label">
                    Any other requests for your virtual guide?
                    <textarea
                        className="personalisation-textarea"
                        value={openInput}
                        onChange={handleOpenInputChange}
                        placeholder="e.g. I am most interested in the artist and their techniques"
                    /> 
                </label> 
                
            </div>
            <a href="/" className="return-to-camera-button">Return to camera</a>  {/* <button onClick={handleReturnToCamera}>Return to camera</button> */}
        </div>
    );
};

export default PersonalisationPage;